import { useRef, useEffect } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import styles from '../styles/Footer.module.css';

gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
    const footerRef = useRef<HTMLElement>(null);
    const triggersRef = useRef<ScrollTrigger[]>([]);

    useEffect(() => {
        return () => {
            triggersRef.current.forEach(trigger => trigger.kill());
            triggersRef.current = [];
        };
    }, []);

    useGSAP(() => {
        if (!footerRef.current) return;

        const socialIcons = gsap.utils.toArray('.social-icon') as HTMLElement[];
        
        // Kill existing triggers
        triggersRef.current.forEach(trigger => trigger.kill());
        triggersRef.current = [];

        socialIcons.forEach((icon) => {
            const trigger = ScrollTrigger.create({
                trigger: icon,
                start: 'top bottom-=100',
                toggleActions: 'play none none reverse',
                onEnter: () => {
                    gsap.from(icon, {
                        scale: 0,
                        opacity: 0,
                        duration: 0.5,
                        ease: 'back.out(1.7)',
                        clearProps: 'all'
                    });
                },
                onLeaveBack: () => {
                    gsap.set(icon, { clearProps: 'all' });
                }
            });
            triggersRef.current.push(trigger);
        });
    }, []);

    return (
        <footer ref={footerRef} className={styles.footer}>
            <div className={styles.socialLinks}>
                <a href="mailto:midnightprojects@gmail.com" className="social-icon" target="_blank" rel="noopener noreferrer">
                    <i className="fas fa-envelope"></i>
                </a>
                <a href="https://linkedin.com/in/sprocket3000" className="social-icon" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"></i>
                </a>
                <a href="/docs/resume.pdf" className="social-icon" target="_blank" rel="noopener noreferrer">
                    <i className="fas fa-file-alt"></i>
                </a>
            </div>
        </footer>
    );
};

export default Footer;