import { useRef, useEffect } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import styles from '../styles/VideoModal.module.css';

interface VideoModalProps {
    isOpen: boolean;
    onClose: () => void;
    videoUrl: string;
}

const VideoModal = ({ isOpen, onClose, videoUrl }: VideoModalProps) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (isOpen && videoRef.current) {
            videoRef.current.load();
            videoRef.current.play().catch(() => {});
        }
    }, [isOpen, videoUrl]);

    useGSAP(() => {
        if (!modalRef.current || !contentRef.current) return;

        if (isOpen) {
            gsap.set(modalRef.current, { display: 'flex' });
            gsap.fromTo(modalRef.current,
                { opacity: 0 },
                { opacity: 1, duration: 0.3 }
            );
            gsap.fromTo(contentRef.current,
                { scale: 0.8, opacity: 0 },
                { scale: 1, opacity: 1, duration: 0.5, ease: 'back.out(1.7)' }
            );
        }
    }, [isOpen]);

    const handleClose = () => {
        if (!modalRef.current || !videoRef.current) return;
        
        // Pause and reset the video
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
        
        gsap.to(modalRef.current, {
            opacity: 0,
            duration: 0.3,
            onComplete: () => {
                gsap.set(modalRef.current, { display: 'none' });
                onClose();
            }
        });
    };

    const handleVideoError = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        const video = e.target as HTMLVideoElement;
        if (video && video.error) {
            // console.error('Video error details:', {
            //     error: video.error,
            //     networkState: video.networkState,
            //     readyState: video.readyState,
            //     currentSrc: video.currentSrc,
            //     src: video.src
            // });
        }
    };

    return (
        <div ref={modalRef} className={styles.modal}>
            <button className={styles.closeButton} onClick={handleClose}>×</button>
            <div 
                ref={contentRef} 
                className={styles.modalContent}
                onClick={e => e.stopPropagation()}
            >
                <video 
                    ref={videoRef} 
                    src={videoUrl} 
                    controls 
                    onError={handleVideoError}
                />
            </div>
        </div>
    );
};

export default VideoModal;
