import { useGSAP } from '@gsap/react';
import { useState } from 'react';
import gsap from 'gsap';
import Header from './components/Header';
import ProjectGrid from './components/ProjectGrid';
import Footer from './components/Footer';
import VideoModal from './components/VideoModal';
import { Project } from './types/Project';
import './App.css';

const projects: Project[] = [
    {
        id: '1',
        title: 'Visualizer',
        imageUrl: '/images/visualizer.jpg',
        videoUrl: '/videos/visualizer.mp4'
    },
    {
        id: '2',
        title: 'Touareg',
        imageUrl: '/images/touareg..jpg',
        videoUrl: '/videos/touareg.mp4'
    },
    {
        id: '3',
        title: 'Taco Bell',
        imageUrl: '/images/taco-bell.jpg',
        videoUrl: '/videos/taco-bell.mp4'
    },
    {
        id: '4',
        title: 'Playze',
        imageUrl: '/images/playze.jpg',
        videoUrl: '/videos/playze.mp4'
    },
    {
        id: '5',
        title: 'Photomatic',
        imageUrl: '/images/photomatic.jpg',
        videoUrl: '/videos/photomatic.mp4'
    },
    {
        id: '6',
        title: 'Paper Football',
        imageUrl: '/images/paper-football.jpg',
        videoUrl: '/videos/paper-football.mp4'
    },
    {
        id: '7',
        title: 'HTC',
        imageUrl: '/images/htc.jpg',
        videoUrl: '/videos/htc.mp4'
    },
    {
        id: '8',
        title: 'Coach Corner',
        imageUrl: '/images/coach-corner.jpg',
        videoUrl: '/videos/coach-corner.mp4'
    },
    {
        id: '9',
        title: 'Birds',
        imageUrl: '/images/birds.jpg',
        videoUrl: '/videos/birds.mp4'
    }
];

function App() {
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);

    useGSAP(() => {
        // Initial page load animation
        gsap.from('body', {
            opacity: 0,
            duration: 1,
            ease: 'power2.inOut'
        });
    });

    const handleProjectClick = (project: Project) => {
        setSelectedProject(project);
    };

    const handleCloseModal = () => {
        setSelectedProject(null);
    };

    return (
        <div className="App">
            <Header />
            <main>
                <ProjectGrid 
                    projects={projects} 
                    onProjectClick={handleProjectClick}
                />
            </main>
            <Footer />
            <VideoModal
                isOpen={!!selectedProject}
                videoUrl={selectedProject?.videoUrl || ''}
                onClose={handleCloseModal}
            />
        </div>
    );
}

export default App;
