import styles from '../styles/Header.module.css';

const Header = () => {
    return (
        <header className={styles.header}>
            <h1 className="header-title">Tri Nguyen</h1>
            <h2 className="header-subtitle">Creative Technologist</h2>
        </header>
    );
};

export default Header;