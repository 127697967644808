import { useRef } from 'react';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import styles from '../styles/ProjectCard.module.css';
import { Project } from '../types/Project';

interface ProjectCardProps {
    project: Project;
    onClick: () => void;
}

const ProjectCard = ({ project, onClick }: ProjectCardProps) => {
    const cardRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);

    useGSAP(() => {
        const card = cardRef.current;
        const overlay = overlayRef.current;

        if (!card || !overlay) return;

        gsap.set(overlay, { opacity: 0 });

        const enterAnimation = () => {
            gsap.to(overlay, {
                opacity: 1,
                duration: 0.3,
                ease: 'power2.out'
            });
        };

        const leaveAnimation = () => {
            gsap.to(overlay, {
                opacity: 0,
                duration: 0.3,
                ease: 'power2.in'
            });
        };

        card.addEventListener('mouseenter', enterAnimation);
        card.addEventListener('mouseleave', leaveAnimation);

        return () => {
            card.removeEventListener('mouseenter', enterAnimation);
            card.removeEventListener('mouseleave', leaveAnimation);
        };
    });

    return (
        <div 
            ref={cardRef}
            className={styles.card}
            onClick={onClick}
        >
            <img src={project.imageUrl} alt={project.title} />
            <div ref={overlayRef} className={styles.overlay}>
                <h3>{project.title}</h3>
            </div>
        </div>
    );
};

export default ProjectCard;
