import { useRef, useEffect } from 'react';
import styles from '../styles/ProjectGrid.module.css';
import ProjectCard from './ProjectCard';
import { Project } from '../types/Project';

interface ProjectGridProps {
    projects: Project[];
    onProjectClick: (project: Project) => void;
}

const ProjectGrid = ({ projects, onProjectClick }: ProjectGridProps) => {
    const gridRef = useRef<HTMLDivElement>(null);
    const timeoutRefs = useRef<NodeJS.Timeout[]>([]);

    useEffect(() => {
        if (!gridRef.current) return;
        
        const cards = Array.from(gridRef.current.querySelectorAll('.project-card')) as HTMLElement[];
        
        // Clear any existing timeouts
        timeoutRefs.current.forEach(timeout => clearTimeout(timeout));
        timeoutRefs.current = [];

        // Hide all cards initially
        cards.forEach(card => {
            card.style.opacity = '0';
        });

        // Shuffle the cards array
        const shuffledCards = [...cards].sort(() => Math.random() - 0.5);

        // Animate each card with a 0.5s delay
        shuffledCards.forEach((card, index) => {
            const timeout = setTimeout(() => {
                card.style.opacity = '1';
                card.style.transition = 'opacity 0.5s ease-out';
            }, index * 300);
            timeoutRefs.current.push(timeout);
        });

        return () => {
            timeoutRefs.current.forEach(timeout => clearTimeout(timeout));
            timeoutRefs.current = [];
        };
    }, []);

    return (
        <div ref={gridRef} className={styles.grid}>
            {projects.map(project => (
                <div key={project.id} className="project-card">
                    <ProjectCard 
                        project={project} 
                        onClick={() => onProjectClick(project)}
                    />
                </div>
            ))}
        </div>
    );
};

export default ProjectGrid;
